.new-plan {
    padding: 5rem;

    .displayCards {
        margin-left: 11.5rem;
        flex-flow: wrap;

        @media screen and (max-width: 576px) {
            margin-left: 0.5rem;
        }

        .card {
            margin-top: 2rem;
            background-color: rgb(211 211 211 / 14%);
            border-radius: 20px;
            width: 26.5vw;
            height: 100vh;
            text-align: left;
            border: none;
            box-shadow: 9px 12px 13px rgb(211 211 211);

            @media screen and (max-width: 576px) {
                width: 100vw;
                height: 75vh;
            }

            b.fs-1 {
                font-size: 2rem !important;
            }

            input.custom-checkbox {
                accent-color: grey !important;
                height: 20px;
                scale: 1.3;
                margin-right: 0.5rem;
            }

            .spanQuartely {
                padding: 0rem;
                background: #57F81F;
                height: 3vh;
                border-radius: 20px;
                padding-inline: 0.6rem;
                color: white;
                font-weight: bolder;

            }

            p.displayParagraph {
                color: gray;
            }

            .spansemi_annually {
                padding: 0rem;
                background: #8e44ad;
                height: 3vh;
                border-radius: 20px;
                padding-inline: 0.6rem;
                color: white;
                font-weight: bolder;
                margin-right: 0.8rem;

            }

            .spanannually {
                padding: 0rem;
                background: #34495e;
                height: 3vh;
                border-radius: 20px;
                padding-inline: 0.6rem;
                color: white;
                font-weight: bolder;
                margin-right: 0.8rem;

            }

            .card-body {
                padding: 2rem;
            }

            :hover {
                background-color: #1F57F8;
                color: white !important;
                border-radius: 20px;

                p.displayParagraph {
                    color: white;
                }

                .monthlyButton,
                .quartelyButton {
                    width: -webkit-fill-available;
                    background-color: #F8C71F;
                    border: none;
                }

                .spanQuartely {
                    padding: 0rem;
                    background: white;
                    height: 3vh;
                    border-radius: 20px;
                    padding-inline: 0.6rem;
                    color: #1F57F8;
                    font-weight: bolder;
                }

                .spansemi_annually {
                    padding: 0rem;
                    background: white;
                    height: 3vh;
                    border-radius: 20px;
                    padding-inline: 0.6rem;
                    color: #1F57F8;
                    font-weight: bolder;
                }

                .spanannually {
                    padding: 0rem;
                    background: white;
                    height: 3vh;
                    border-radius: 20px;
                    padding-inline: 0.6rem;
                    color: #1F57F8;
                    font-weight: bolder;
                }

                .semi_annuallyButton {
                    width: -webkit-fill-available;
                    background-color: #F8C71F;
                    border: none;
                    color: white;

                }

                .annuallyButton {
                    width: -webkit-fill-available;
                    background-color: #F8C71F;
                    border: none;
                    color: white;

                }

                h3 {
                    color: white !important;

                }
            }


            .monthlyButton {
                width: -webkit-fill-available;
                background-color: #1F8CF8;
                border: none;

            }

            .quartelyButton {
                width: -webkit-fill-available;
                background-color: #57F81F;
                border: none;
                color: white;

            }

            .semi_annuallyButton {
                width: -webkit-fill-available;
                background-color: #8e44ad;
                border: none;
                color: white;

            }

            .annuallyButton {
                width: -webkit-fill-available;
                background-color: #34495e;
                border: none;
                color: white;

            }
        }
    }
}

.topBorderDisplay{
    height: 25vh;
    @media screen and (max-width: 576px) {
        height: 15vh;
    }
}