.navbarBottom{
    position: fixed;
    bottom:0;
    width: 100%;
    padding:0rem;
    background-color: var(--secondary-color);
    color: var(--gAsbestosGrey-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.navbarBottom .navbar{

    background-color: transparent;
    width: auto;
    place-content: center;
    border-right: 1px solid lightgray;


}

.navbarBottom .navbar .container{
  
}
.navbarBottom .navlinkicon {
    display: block;
    color: var(--gAsbestosGrey-color);
    font-size:23px;
  }
  
.navbarBottom .NavLink:hover .navlinkicon,
.NavLink.active .navlinkicon {
  color: white;
}
.navbarBottom .NavLink:hover .navlinkSpan,
.NavLink.active .navlinkSpan {
  color: white;
}
.navbarBottom .NavLink {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    letter-spacing: 0.05rem;
    color: var(--gAsbestosGrey-color);
    text-decoration: none;
}
  
.navbarBottom  .navlinkSpan{
    font-size: 9px;;
  }