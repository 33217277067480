/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */



//   // new data


//   .rent-income-chart {
//     margin-top: 18rem;
//     margin-left: 8rem;
//     padding: 12rem;
// }
// last of new data
.rentexpenses {
  position: relative;
  margin-top: 38rem;
  margin-inline: 19rem;
  border-radius: 20px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    overflow-x: scroll;
    margin-top: 0rem !important;
    margin-inline: 18rem !important;

  }

  @media only screen and (max-width: 768px) {
    width: 50%;
    overflow-x: scroll;
    margin-top: 0rem !important;
    margin-inline: 30rem !important;

  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    zoom: 0.4;
    left: 13%;
    overflow-x: scroll;
    margin-top: 0rem !important;

  }
}

.rentexpenses h2 {

  padding-bottom: 1rem;

  @media only screen and (max-width: 576px) {
    padding-bottom: 1rem;
    font-size: 3rem;
    left: -16%;
    position: relative;

  }
  
}

.recharts-wrapper {
  box-shadow: rgba(12, 12, 46, 0.15) 10px 11px 25px 1px;
  border-radius: 20px;
}

svg.recharts-surface {
  padding: 1rem;

  @media only screen and (max-width: 576px) {

    padding: 0rem;

  }
}

.title {
  display: block !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.title {
  text-align: start;
}

.apexcharts-canvas {
  margin-left: 2rem;

  @media only screen and (min-width: 1800px) {
    margin-left: 0;
    width: 100% !important;

  }
}