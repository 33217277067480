.addButton {
    position: fixed;
    bottom: 7.5%;
    background: white;
    width: 100%;
    padding: 1rem;
    text-align-last: right; 
}


.addButton .buttonMain{
  border-radius: 50%;
  font-size: 20px;
  font-weight: bolder;
  background-color: var(--primary-color);
  color:white;
  border:none;
}



.buttonsAdd {
  border-radius: 5px;
  margin-bottom: 2rem;
  font-size: 12px;
  background-color: var(--primary-color);
  color:white;
  border:none;
}


.buttonsGroup {
  display: grid !important;
  float: right;
  width: 20%;
  background-color: transparent;
}

.buttonIcons {
  display: flex;
  width: 11rem;
  justify-self: right;
  justify-content: space-between;
  margin-right: 1rem;
  border: none;
}

.buttonIcons i {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  height: fit-content;
  color:var(--primary-color);
  
}


