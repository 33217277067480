/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */

.selected-property {
  padding: 6rem;
  margin-left: 11rem;
  float: left;

  // @media screen and (max-width: 992px) {
  //   padding: 5rem;
  // }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-left: 0;
  }

  @media screen and (max-width: 576px) {
    overflow-x: inherit;

  }

  .ImageHouseName {
    height: 10rem;


    @media screen and (max-width: 768px) {
      height: 6.5rem;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-inline: 1.5rem;
    }

    button {
      float: right;
      padding: 5px;
      margin-top: 0.5rem;

      @media screen and (max-width: 576px) {
        margin-top: 6.5rem;
      }
    }

    button.addHouse {
      float: right;
      padding: 5px;
      margin-top: 0.4rem;

      @media screen and (max-width: 576px) {
        margin-top: 6.3rem;
      }
    }

    h2 {
      color: white;
      text-shadow: 4px 6px 14px #000000;
      font-size: 3.2rem;
      padding: 0rem;
      width: 99%;
      display: block;
      text-transform: uppercase;
      // margin-inline: 13rem;

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        width: 100%;
        margin-inline: 0rem;

      }
    }

    span.properties {
      color: white;
      padding: 1rem;
      margin-top: 1rem;
      display: block;
      margin-left: 0 !important;
      font-weight: 900;
      text-transform: uppercase;
      text-shadow: 4px 6px 14px #000000;

      @media screen and (max-width: 576px) {
        font-size: 1rem;
        margin-top: 0rem;
        width: 100%;
        display: grid;
        position: relative;


      }
    }
  }
}




.topSelectedProperty {
  width: 100%;
  margin: 0;
  border-radius: 0.5em;
  height: fit-content;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    margin-top: 9rem;
  }

  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: inherit;
    display: flex;
  }
}






.CardHouseName {
  box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
  padding: 1rem;
  display: flex;
  justify-content: start;
  flex-flow: wrap;
  border-radius: none;
  border-radius: 0px;
  margin-top: -3rem;
  height: 5rem;
  width: 95%;
  margin-inline: 1.5rem;

  .searchProperty {
    padding: 1rem;
    width: 40%;
    @media screen and (max-width: 576px) {
      width: 100%;
  
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    height: auto;
    margin-top: 0;
    margin-inline: 0;
    width: auto;

  }

  @media screen and (max-width: 576px) {
    display: block;

  }
}

select.formProperty.form-select.form-select-sm {
  height: 40px;
  margin-top: 1rem;
  width: 100%;
  margin-right: 0rem;
  @media screen and (max-width: 576px) {
    width: 100% !important;

  }

}

.formSelectedProperty {
  width: 28%;
  margin-inline: 1rem;
  margin-right: 0rem;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-bottom: 1rem;

  }
}
.filterCalendar{
  @media screen and (max-width: 576px) {
   width: 32%;
  }
}
.metricsCards {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
  zoom: 0.7;

  @media screen and (max-width: 576px) {
    margin-top: 6rem;
    display: flex;
    flex-flow: wrap;
  }

  .card {
    width: 18%;
    border: none;
    border-radius: 30px;

    @media screen and (max-width: 576px) {
      width: 45%;
      margin-bottom: 1.5rem;
      margin-inline: 0.5rem;
    }
  }

  .card-body {
    display: flex;

    .float-left {
      text-align-last: left;

      .d-flex.mt-2 {
        p {
          font-size: 25px;
        }

      }
    }

    img {
      padding: 0.7rem;
      background-color: white;
      border-radius: 102%;
      align-self: center;
      margin-inline: 1rem;
      font-size: 2rem;
      width: 25%;
    }

  }

}



.formCalendar {
  margin-bottom: 1rem;
  margin-top: 1rem;
  float: right;
  width: 25%;
}


.calendar {
  width: 100%;
  margin-top: 2rem;
}

.topProperty {
  margin-top: 3rem;
  box-shadow: rgba(12, 12, 46, 0.15) 0px 0px 6px 2px;
  height: 100%;

  .card-header {
    font-weight: bold;
  }

  .card-body .row .col-md-6 span {
    float: left;
    margin-bottom: 1rem;
    // font-size: 16.5px;
    font-size: inherit;
  }

}

.d-flex.col-md-9.col-sm-6 {
  flex-wrap: wrap;
}

.buttonsProperty {
  display: flex;

  .OccupiedBtn {
    border-radius: 20px;
    margin-inline: 0.5rem;
  }
}

.house {
  width: 27%;

  @media screen and (min-width: 1580px) {
    width: 21%;
  }

  @media screen and (min-width: 1900px) {
    width: 16%;
  }

  @media screen and (min-width: 2400px) {
    width: 14%;
  }
  @media screen and (max-width: 576px) {
    width: 45%;
  }

  margin-inline: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 3rem;

  .houseUnit {
    display: block;
    width: 100%;
    height: 10rem;
    border-radius: 0.5em;
  }

  p.text-muted.float-left {
    float: left;
    font-size: 21px;
    line-height: 2;
    margin-bottom: 0;
  }

  p.houseName {
    clear: left;
    color: black;
    text-align-last: left;
    // margin-left: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }

  p.houseTitle {
    color: #95a5a6;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
  }

}

.noHouse {
  margin-top: 3rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 2rem;
  color: #95a5a6;
}

.addHouse {
  text-align-last: right;
}

// loading designs 
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #00bfff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loader-rotate 1s infinite linear;
  margin-bottom: 10px;
}

@keyframes loader-rotate {
  to {
    transform: rotate(360deg);
  }
}

.amenitiesDiv {
  display: flex;
  justify-content: space-between;

  i {
    border-radius: 50%;
  }

  margin-bottom: 0.5rem;
}
.selectedHouseButtonsTop {
  position: absolute;
  top: 13%;
  right: 5%;
}