.addTenant{
    margin-top: 0rem;
    padding: 1rem;
    margin-inline: auto;


    h2{
        text-transform: uppercase;
    }
    .noTenantAlert {
        position: absolute;
        top: 40%;
        left: 30%;
        text-transform: capitalize;
        font-size: 3rem;
        font-weight: bold;
        color:#95a5a6;
    }
    table{
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        padding: 1rem;
        .tableThead{
            th{
                padding: 1rem;
            }
        }
    }   
}