@import '../../index.css';
.registerForm {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 30%;
  margin-inline: auto;
  padding: 1rem;
  margin-top: 2%;
}
@media (max-width: 800px) and (min-width: 100px) {
  .registerForm {
    width: 80%;
    margin-top:4rem ;
  }
}
.registerForm h2 {
  font-size: 22px;
  }

.custom-back-button {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: rgb(196, 195, 195);
  margin: 5px 0px 2px 10px; /* top | right | bottom | left */
}

.custom-back-button::before {
  content: "<";
  font-size: 25px;
  font-weight: bold;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 18px;
}

.icon {
  padding: 15px;
  color: grey;
  min-width: 50px;
  text-align: center;
}

.input-field,
.input-field:focus {
  width: 100%;
  padding: 10px;
  text-align: start;
  padding-left: 50px;
  border-radius: 17px;
  border: 1px solid grey;
}
.genderRadio {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

input[type="radio"] {
  display: none;
}
label {
    border: 1px solid grey;
    padding: 10px;
    width: 12vw;
    border-radius: 17px;
    color: grey;
    font-size: 14.5px;
}
.privatePolicy {
  color: orange;
}

input[type="radio"]:checked + label {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.nextBtn {
  width: 75%;
  margin-inline: 10%;
  height: 6vh;
  border-radius: 30px;
  background-color: var(--primary-color);
  margin-bottom: 2rem;
  border: none;
}
.fullnameerror{
 
    color: var(--error-color);
    font-size: 10px;
    position: relative;
    float: right;
    margin-bottom: 5px;
    margin-top: -10px;
    padding-top: -24px;

}
.lableName{
  margin-left:4%;
  margin-bottom:-1%
}
