.footer_landing {
  position: relative;
  width: 100%;
  height: auto;
}
.buttons_footer {
  width: 50%;
  margin-inline: auto;
  margin-bottom: 2rem;

}
.sign_up {
  color: black;
  background-color: lightgray;
  border-radius: 60px;
  width: auto;
  padding: 5px;
  overflow-wrap: anywhere;

}
.login {
  border-radius: 60px;
  width: auto;
  background-color: var(--primary-color);
  padding: 5px;
  overflow-wrap: anywhere;
}
