.repair_apartment {
    padding: 5rem;
    text-align: left;
    margin-left: 12rem;
    @media screen and (max-width: 576px) {
        margin-left: 0rem;
        padding: 2rem;

    }


    .tasks {
        margin-top: 0rem;
        width: 103%;
        padding: 2rem;
        @media screen and (max-width: 576px) {
            margin-top: 5rem !important;    
        }
        .add_repair {
            float: right;
            font-size: 0.8rem;
        }
        h2 {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.5rem;
            margin-top: 4rem;
            @media screen and (max-width: 576px) {
                font-size: 0.5rem !important;
    
        
            }
        }

        button.many {
            float: right !important;
            border: none;
            color: white;
            width: 5%;
            margin-bottom: 2rem;
        }

        .table {
            .table-body {
                button {
                    width: auto;
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;

            button {
                border: none;
                padding: 0.5rem;
                border-radius: 5px;
                background: #81c5d9;
                color: white;
                font-weight: bold;
            }

            span {
                font-size: large;
                font-style: italic;
            }
        }

    }


    .cardProperty.card {
        margin-inline: 3rem;
        width: 90% !important;
        padding: 1rem;
        margin-top: 2rem;

        select.formProperty.form-select.form-select-sm {
            height: 40px;
            margin-top: 1rem;
            width: 100%;
            margin-right: 9rem;
        }

        select.formPropertySearch.form-select.form-select-sm {
            height: 40px;
            margin-top: 1rem;
            width: 100%;
            margin-right: 0rem;
            margin-left: 0rem;
        }
    }

    .image_Apartment_report {
        img {
            width: 100%;
            height: 30vh;
            object-fit: cover;
        }

        p {
            margin-top: -10rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 3rem;
            color: white;
            font-weight: bold;
            text-shadow: 4px 6px 14px #000000;
            @media screen and (max-width: 576px) {
                font-size: 2rem;
                margin-top: -12rem;

        
            }
        }

        span {
            // margin-inline: 28rem;
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-size: 1rem;
            color: white;
            font-weight: bold;
            dow: 4px 6px 14px #000000;
        }
    }


}