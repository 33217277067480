.bulk {
    padding: 5rem;

    @media screen and (max-width: 576px) {
        padding: 1rem;
    }

    .topSelectedProperty {
        height: 40vh;
        border-radius: 0.5em;
        margin-inline: 12.5rem;
        width: 90%;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: 768px) {
            margin-top: 9rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: 1rem;
            margin-inline: 0.5rem;
            width: 100%;


        }

        .properties {
            color: white;
            padding: 3rem;
            font-size: 3rem;
            margin-left: 0 !important;
            font-weight: 900;
            text-transform: uppercase;
            text-shadow: 4px 6px 14px #000000;

        }
    }

    .tasks {
        margin-top: 0rem;
        width: 90%;
        padding: 2rem;
        margin-left: 12rem;

        @media screen and (max-width: 576px) {
            margin-left: 1rem;
        }

        h2 {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.5rem;
        }

        button.many {
            float: right !important;
            border: none;
            color: white;
            width: 8%;
            margin-bottom: 2rem;
        }

        .table {
            .table-body {
                button {
                    width: auto;
                }
            }

            p {
                color: var(--primary-color);
                text-transform: uppercase;

            }
        }


    }
}

.topButtonsBulk {
    zoom: 0.9;
}