.blogAdd {
    margin-left: 15rem;
    padding-top: 5rem;
    padding: auto;

    @media screen and (max-width: 576px) {
        margin-left: 0rem;
    }

    h2 {
        text-transform: uppercase;
    }

    .input-field,
    .input-field:focus {
        width: 100%;
        padding: 10px;
        outline-color: transparent;
        text-align: start;
        padding-left: 50px;
        border-radius: 0;
        font-size: 15px;
        border: 0px solid grey;
        border-bottom: 1px solid grey;
    }

    .imageUploaded.cover {
        height: 25vh;
    }

    .imageUploaded.additional {
        height: 15vh;
        width: 13vw;
        object-fit: cover;
    }

    .leftCard {
        width: 95%;
        margin-left: 1.5rem;
        padding: 2rem;
        box-shadow: 1px -6px 45px -7px lightgray;
        border: 0;
    }

    .rightCard {
        width: 45%;
        padding: 2rem;
        margin-right: 3.5rem;
        box-shadow: -20px -5px 60px -9px lightgray;
        border: 0;
    }

    // i.bi.bi-trash-fill.text-danger {
    //     position: relative;
    //     right: -8%;
    //     top: -42%;
    // }
}

div#toolbar {
    zoom: 1.2;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    padding: 3px 5px;
    width: 55px;
}

.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    font-size: 1.3rem;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.saveBlog {
    width: 10%;
    margin-top: 2rem;
    float: right;
    @media screen and (max-width: 576px) {
        width: auto;
    }
}