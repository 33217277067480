

:root {
  --font-family: "Axiforma", sans-serif;
  --primary-color: #3498db;
  --secondary-color: #1b3a46;
  --tabhome-color:#F5F5F5;
  --gAsbestosGrey-color:#7f8c8d;
  --silvergrey-color:#bdc3c7;
  --yellowsunflower-color:#f1c40f;
  --AlizarinRed-color:#e74c3c;
  --EmeraldGreen-color:#2ecc71;
  --materialorange-color:#f39c12;
  --transluscent-color:#FFFFFF;
  --WisteriaPurpl-color:#8e44ad;
  --outline-color:#8181f5;
  --error-color:#D88687;

  
}
body {
  zoom: 0.8 !important;
  margin: 0;
  font-family:var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2,h3,h4,h5,h6,p,span{
  font-family:var(--font-family);
  overflow-wrap: break-word;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
