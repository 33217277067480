.selected-property {
  float: none;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-left: 0;
  }

  @media screen and (max-width: 576px) {
    overflow-x: inherit;
    padding-top: 4rem;
    padding-inline: 0.5rem;

  }

  .ImageHouseName {
    height: 15rem;

    @media screen and (max-width: 576px) {
      padding-top: 5rem;

      button {
        margin-top: 11rem;
      }

    }

    .button_span {
      margin-top: 0rem;

    }

    // span {
    //   // margin-left: 18rem !important;
    //   margin-left: 6rem !important;

    // }

    div {
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0rem;
      text-shadow: 2px 1px 5px #000000;
    }
  }

  .topSelectedSecurity {
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .topSelectedWater {
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .topSelectedInterent {
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .topSelectedElectricity {
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .waterTab {
    color: #FFFFFF;
    background-color: #3498db;
  }

  .electricityTab {
    color: #FFFFFF;
    background-color: #e67e22;
  }

  .garbageTab {
    color: #FFFFFF;
    background-color: #27ae60;
  }

  .securityTab {
    color: #FFFFFF;
    background-color: #c0392b;
  }

  .internetTab {
    color: #FFFFFF;
    background-color: #8e44ad;
  }

  .topSelectedGarbage {
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .selected-property .ImageHouseName button {
    @media screen and (max-width: 576px) {
      margin-top: 10.5rem;

    }
  }

  .topSelected {
    background-image: url('../../assets/img/house4.jpg');
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      margin-top: 9rem;
    }

    @media screen and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  .navtabs {
    margin-top: 2rem;
  }

  .tenant_utilities {
    display: flex;
    flex-flow: wrap;

    .house {
      width: 20%;

      .card-body {

        .buttonsProperties {
          p {
            font-size: 0.9rem;
            margin-top: 0.2rem;
          }

          p.houseTitle {
            color: #95a5a6;
            font-size: 21px;
            font-weight: bold;
            text-transform: uppercase;
          }

          button.btn.btn-warning.text-white.btn.btn-primary {
            padding: 0.5rem;
            height: fit-content;
          }

          button.btn.btn-sm.btn-success.btn-primary {
            padding: 0.5rem;
            height: fit-content;
          }
        }
      }
    }
  }

}


// .modal {
//   .modal-content {

//     .modal-body {
//       align-self: center !important;
//     }
//   }
// }

.NotFound {
  padding: 20rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 3.5rem;
}

.notUtility {

  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 2rem;
}

.utilityHelp {
  text-align: start;
  margin-bottom: 0;
  color: gray;
}

.addUtiilitySelect {
  width: 100%;
  padding: 13px;
  outline-color: var(--outline-color);
  text-align: start;
  padding-left: 50px;
  border-radius: 17px;
  font-size: 15px;
  border: 1px solid grey;
  color: grey;
}