.blogDetails {
    .card {
        border: 0;
        text-align: left;
        box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;

        h2 {
            color: gray;
        }
    }

    .blogImages {
        img {
            height: 50vh;
            width: 45vw;
            object-fit: cover;
            margin-bottom: 1.5rem;
        }

        i.bi.bi-trash-fill.text-danger {
            position: absolute;
            margin-top: 0.5rem;
            margin-left: 0.2rem;
        }
    }
}