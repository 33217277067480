/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */


//  the top of the page this is the container that holds all the contents on the header  
.headerDashboard-light {
    border-bottom: 1px solid lightgray;
    float: right;
    width: calc(100% - 250px);
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1020;
    background: white;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 0;

    @media screen and (max-width: 768px) {
        width: 100% !important;
        border-bottom: none;
        position: absolute;
    }

    @media screen and (max-width: 576px) {
        overflow: hidden;
        justify-content: space-between;
        position: relative;
        width: 45% !important;
    // border-bottom: none;
    // margin-top: 4rem;
    // position: absolute !important;
    // z-index: -300;
    }

    // the name of the page and the icon that closes the sidebar when clicked
    .left {
        margin-left: 1rem;
        padding: 1rem;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .left span {
        margin-left: 1rem;

    }

    // end


    // the other side of the header contains the profile and the search bar
    .right {
        display: flex;
        margin-right: 1rem;

        @media screen and (max-width: 768px) {
            width: 100%;
            display: contents;
            float: right;
        }

        .dropdown-menu.show {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate(-19px, 51px) !important;
            @media screen and (max-width: 576px) {
                transform: translate(285px, 51px) !important;
            }
    
        }
    }
}


.headerDashboard-dark {
    border-bottom: 1px solid lightgray;
    float: right;
    width: calc(100% - 250px);
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1020;
    // background: #00000000;
    background-color: rgb(25 23 23);
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 0;

    @media screen and (max-width: 768px) {
        width: 100% !important;
        border-bottom: none;
        position: absolute;
    }

    @media screen and (max-width: 576px) {
        overflow: hidden;
        justify-content: space-between;
        position: relative;
    }

    // the name of the page and the icon that closes the sidebar when clicked
    .left {
        margin-left: 1rem;
        padding: 1rem;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .left span {
        margin-left: 1rem;

    }

    // end


    // the other side of the header contains the profile and the search bar
    .right {
        display: flex;
        margin-right: 1rem;

        @media screen and (max-width: 768px) {
            width: 100%;
            display: contents;
            float: right;
        }

        .dropdown-menu.show {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate(-19px, 51px) !important;
            @media screen and (max-width: 576px) {
                transform: translate(285px, 51px) !important;
            }
    
        }
    }
}







.profile {
    background-color: transparent;
    border: none;
    color: black;
    margin-top: 0.6rem;
}

.profile:hover {
    background-color: transparent;
    border: none;
    color: black;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: black;
    background-color: transparent;
    border: none;
}

.dashSearch {
    margin-right: 2.5rem;
    float: right;
    margin-bottom: 1rem;
    padding: 0.3rem;
}

.userImage {
    width: 35px;
    height: 34px;
    margin-top: 0.2rem;
    margin-right: 0.5rem;
    border-radius: 50%;
}

// end


/* media queries */
@media only screen and (max-width: 900px) {


    .right {}

    .dropdown-menu.show {
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate(39px, 51px) !important;
    }

    .dashSearch {
        /* margin-right: 2.5rem; */
        float: right;
        margin-inline: 0.5rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}
.notificationDot{
    padding: 0.5rem;
    background-color: lightgreen;
    position: absolute;
    border-radius: 50%;
    top: 2%;
    right: 1%;
    
}