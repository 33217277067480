.account {
    padding: 5rem;

    .tasks {
        margin-top: 0rem;
        margin-left: 13rem;
        background-color: white;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: rgba(12, 12, 46, 0.15) 10px 11px 25px 1px;

        .table {
            tbody {
                td.w-10 {
                    width: 15%;

                    img {
                        border-radius: 50%;
                        width: 18%;
                    }
                }

                td {
                    .btn.btn-sm.btn-success.text-white {
                        background-color: var(--EmeraldGreen-color);
                        border: none;
                    }

                }
                td.landlord{
                    color: var(--EmeraldGreen-color);
                }
                td.agent{
                    color: #8e44ad;
                }
                td.caretaker{
                    color:#f39c12;
                }
               

                }
            }
        }
    }

