.selectedHouse {
  padding: 5rem;
  margin-left: 0;

  @media screen and (max-width: 1000px) {
    zoom: 0.9;
    margin-left: 10%;
    padding: 0;
  }

  .selectedHouseCarousel {
    margin-top: 0.5rem;
    margin-inline: 15rem;

    img {
      width: 100% !important;
      height: 16rem;
      object-fit: cover;
    }
  }

  .selectedHouseImageContent {

    h2 {
      color: white;
      font-size: 4rem;
      position: relative;
      margin-top: -15rem;
      text-shadow: 6px 4px 9px #767272;
      width: 30%;
      left: 41%;

      @media only screen and (max-width: 768px) {
        left: 10%;
        width: 80%;
      }

      // margin-inline: 49rem;
    }

    span {
      color: white;
      font-size: 1.5rem;
      position: relative;
      text-transform: uppercase;
      margin-top: -15rem;
      left: 6%;
      text-shadow: 6px 4px 9px #767272;
      width: 10%;

      // margin-inline: 49rem;
      @media only screen and (max-width: 768px) {
        left: 0%;

      }
    }


    .buttonsSelectedHouse {
      z-index: 1;
      display: flex;
      width: auto;
      position: relative;
      left: 85%;

      // margin-left: 42rem;
      margin-top: 3rem;

      @media screen and (max-width: 650px) {
        width: 95%;
        left: 0;
        margin-top: 2rem;
        margin-inline: 0rem;
      }
    }
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 650px) {
      width: 100%;

    }
  }

  .selectedHouseStatus {
    margin-top: 4rem;
    float: left;
    // width: 35%;
    display: flex;
    justify-content: start;
    margin-left: 12rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 650px) {
      margin-left: 0rem;
      margin-top: 5rem;
      display: block;

    }
  }

  .selectedHouseTenant {
    position: relative;
    width: 20%;
    text-align: left;
    margin-inline: 11.5rem;
    margin-top: 1rem;
    text-transform: capitalize;
    @media screen and (max-width: 576px) {
      width: 65%;
      text-align: left;
      margin-inline: -0.5rem;
      margin-top: 11rem;

    }
  }

  .Rent {
    width: fit-content;
    right: -3.5%;
    color: #27ae60;
    margin-inline: 1rem;
    position: relative;
  }

  .selectedHouseDetails.row {
    margin-left: 7.6rem;

    @media screen and (max-width: 650px) {
      margin-left: -3.5rem;

    }

    h5.float-left {
      float: left;
      margin-left: 3.5rem;

      @media screen and (max-width: 650px) {
        margin-left: 2rem;
      }
    }

    p.descriptionHouseSelected {
      width: 85%;
      margin-top: 4rem;
      margin-left: 3.5rem;
      text-align: justify;
      color: gray;

      @media screen and (max-width: 650px) {
        margin-left: 2rem;
      }
    }
  }

  button {
    zoom: 0.9;
  }

  .selectedHouseTenantDetails {
    box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
    border: none;

    .noTenantCard {
      p {
        font-size: 2rem;
        position: absolute;
        top: 40%;
        left: 30%;
      }
    }

    .card-body {
      height: 26rem;
      zoom: 0.9;

      @media (min-height: 1000px) {
        height: 32rem;
      }

      img {
        width: 25%;
        border-radius: 50%;
      }

      .badge {
        position: absolute;
        background-color: red;
        border-radius: 50%;
        padding: 0.5rem;
        font-size: 0.8rem;
        text-align: center;
        margin-left: -1rem;
      }

      .tenantRatings {
        color: orange;
        justify-content: center;
        margin-top: 1rem;
      }

      .tenantCardButtons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 3rem;
      }
    }
  }

  .selectedHouseMetrics {
    margin-top: 2rem;
    width: 90%;
    box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
    margin-left: 3rem;
    border: none;

    @media (max-width: 650px) {
      width: 98%;
      margin-left: 0rem;

    }

    .metricBadge {
      padding: 0.5rem;
      background: lightgray;
      border-radius: 100%;
    }

    .row {
      text-align: left;
    }
  }
}

.modal .modal-content .modal-body {
  align-self: auto !important;
}

.slick-slider {
  position: relative;
  display: block;
  margin-left: 13rem;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: 650px) {
    margin-left: -3rem;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: black;
    outline: none;
    background: transparent;
  }
}

.slick-prev {
  font-size: 2rem;
  color: black;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 30px !important;
  z-index: 10;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  border: none;
  outline: none;
}

.slick-next {
  font-size: 2rem;
  color: black;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 60px !important;
  z-index: 10;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  border: none;
  outline: none;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 25px !important;
  line-height: 1;
  opacity: 1 !important;
  color: white;
  text-shadow: 6px 4px 9px #767272;
  border-radius: 100% !important;
  padding: 0rem;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  position: relative;
  opacity: 0.85 !important;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

a.addHouse {
  text-decoration: none;
  align-self: center;
  color: black;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;

  i.bi.bi-arrow-left {
    background: blue;
    padding: 0.8rem;
    border-radius: 50%;
    color: white;
    font-size: 1.2rem;
  }
}

.badgeRating {
  position: absolute;
  top: 10px;
  width: 30px;
  text-align: center;
  height: 30px;
  right: 36%;
  // right: '165px',
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-weight: bold;
  font-size: 12px;

  @media only screen and (min-width: 1700px) {
    right: 40%;
  }
}

.unitRoomImage {
  width: 10vw !important;
  margin-bottom: 1rem;
  border-radius: 10px !important;
  margin-right: 2.5rem;
  height: 20vh;
  object-fit: cover;
  @media only screen and (max-width: 576px) {
    width: 25vw !important;
  }
}

.slick-slider.slick-initialized {
  margin-left: 12rem;

  @media only screen and (max-width: 788px) {
    margin-left: -1rem;
  }
}

.unitRoomDelete {
  position: relative;
  left: 3%;
  color: white;
  padding: 0.2rem;
  background: red;
  border-radius: 50%;
  bottom: 25%;
}

.selectedHouseStatusRight {
  display: flex;
  margin-top: 4rem;
  float: right;
  @media screen and (max-width: 576px) {
    float: left;
    margin-top: 1rem;
    margin-left: -13.5px;

  }
}