
/* the body of the page */
.homePage{
  
}
/* the top div with the same color as the navbar */
.headerHome{
    position: fixed;
    top:0;
    width: 100%;
    height: 36%;
    padding:0rem;
    background-color: var(--secondary-color);

}

/* title kodi */
.title{
    font-size: 30px;
    font-family: 'Fonarto', sans-serif;
    color: white;
}

/* cards on top */
.topCards{
    display: flex;
    color:white;
    position: relative;
    justify-content: space-between;
}

.topCards .card {
    width: 30%;
    background-color: #4c5a66;
    /* background-color: rgb(221 201 201 / 25%); */
    height: 5.5rem;
    border-radius: 15px;
}
.topCards .card h3{
    font-size:20px;
    align-self: self-start;
    padding: 0.5rem;
}
.topCards .card p{
    font-size:11px;
    align-self: self-start;
    padding: 0.5rem;
}

/* loner Apartment */
.ApartmentCard {
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: black;
    width: 35%;
    border-radius: 10px;
}
.houseImage {
    margin-top: 0.3rem;
    margin-inline: 0.3rem;
   
}
.imageCardLoner {
    width: 100%;
    height: 9rem;
}

/* see all link */
.seeAll {
    display: flex;
    color: #8181f5;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
}
.seeAll p {
    text-align-last: right;
}

/* the houses and details of it */
.imageCard {
    width: 25%;
    border-radius: 1.5rem;
    padding: 0.5rem;
}
.housesCards {
    flex-flow: wrap;
    border-radius: 1.5rem;
    margin-bottom: 1rem;
}
.houseDetails {
    margin-inline: 3rem;
    text-align-last: start;
    padding: 0.5rem;
}
.houseDetails h3{
    font-size: 20px;
    font-family: var(--font-family);
    font-weight: bolder;
}
.housePerson::before {
    background: #48c9f6;
    color: white;
    border-radius: 50%;
    padding: 0.05rem;
    margin-inline-end: 0.5rem;
}

.houseDetails .btn {
border-radius: 0.5rem;
}

.houseDeatilsCard{
    margin-bottom: 5rem;
    width:75%
}
.houseButtons {
    display: flex;
    justify-content: space-between;
}

/* media queries */
@media only screen and (max-width: 500px) {
    .houseImage {
        width: 90%;
        margin-top: 0.3rem;
        margin-inline: 0.5rem;
        border-radius: 10px;
    }
    .ApartmentCard {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: black;
        width: 50%;
     
    }
    .topCards .card {
        width: 30%;
        background-color: #4c5a66;
        /* background-color: rgb(221 201 201 / 25%); */
    
        border-radius: 15px;
    }
    .imageCard {
        width: 50%;
        border-radius: 1.5rem;
        padding: 0.5rem;
    }
    .houseDetails {
        width: 50%;
        text-align: start;
        margin-inline: auto;
    }
    .housesCards {
        flex-flow: row;
        border-radius: 1.5rem;
        margin-bottom: 1rem;
    }
  }

  @media (max-width: 900px) and (min-width: 500px) {
    .houseImage {
        width: 93%;
        margin-top: 0.3rem;
        margin-inline: 0.5rem;
        border-radius: 10px;
    }
    .ApartmentCard {
        margin-top: 4rem;
        margin-bottom: 1rem;
        color: black;
        width: 50%;
     
    }
    
  }