.imageProperty {
    box-shadow: rgba(17, 12, 46, 0.15) 10px 48px 100px 15px;
    width: 75%;
    margin-inline: auto;
    padding: 1rem;
    margin-top: 2%;
    height: 100vh;
    border-radius: 10px;
}
.imageProperty h2 {
    font-size: 20px;
    padding: 0.7rem;

}
.imageButton {
    margin-bottom: 2rem;
    background-color: var(--primary-color);
    color:white;
}

.imageUploaded {
    width: auto;
    max-height: 50vh;
    margin-bottom: 2rem;
}
.bottomButtons {

    margin-inline: auto;
    display: flex;
    justify-content: center;
    width: 60%;

}
.nextButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    width: 10%;
    overflow-wrap: inherit;
}
.skipButton {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    width: 10%;
    margin-inline-end: auto;
    overflow-wrap: inherit;
}
.skipButton:hover,
.skipButton:focus,
.skipButton:active{
    background-color: var(--secondary-color) !important;
    color:white;
    border:none;
    width:10%;
}