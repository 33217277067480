/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */

.SideBar {
  position: absolute;
  z-index: 10;

  @media screen and (max-width: 576px) {
    height: 100%;
  }
}

// dark mode
.sidebar-light {

  /* the design for the side bar that contains the navigations */
  .sidebar {
    position: fixed;
    top: 0px;
    margin-top: 3rem;
    left: -250px;
    height: 100%;
    border-right: 1px solid lightgray;
    width: 250px;
    z-index: 1;
    overflow-y: scroll;
    padding: 20px;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
      z-index: 10;
      background-color: white;
    }

    @media screen and (max-width: 576px) {
      position: relative;
      margin-top: -1rem;

    }

  }

  .sidebar.show {
    left: 0;
  }

  /* the design that contains the kodi logo on the side bar */
  .navbar {
    background-color: white;
    width: 250px;
    place-content: center;
    border-right: 1px solid lightgray;
    position: fixed;

    @media screen and (max-width: 576px) {
      width: 40% !important;
      overflow-x: hidden;
      margin-top: 0rem;
      background-color: transparent;
      position: relative;
      border-right: 1px solid transparent;

    }

  }

  .nav-link {
    font-size: 16px;
    color: #343a40;
    text-align: center;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    display: flex;
  }
}

.sidebar-dark {

  /* the design for the side bar that contains the navigations */
  .sidebar {
    position: fixed;
    top: 0px;
    margin-top: 3rem;
    left: -250px;
    height: 100%;
    border-right: 1px solid lightgray;
    width: 250px;
    z-index: 1;
    overflow-y: scroll;
    padding: 20px;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
      z-index: 10;
      background-color: #00000000
    }

    @media screen and (max-width: 576px) {
      position: relative;
    }

  }

  .sidebar.show {
    left: 0;
  }

  /* the design that contains the kodi logo on the side bar */
  .navbar {
    background-color: #00000000;
    width: 250px;
    place-content: center;
    border-right: 1px solid lightgray;
    position: fixed;
    margin-top: -1rem;

    @media screen and (max-width: 576px) {
      width: 44% !important;
      overflow-x: hidden;

    }

  }

  .nav-link {
    font-size: 16px;
    color: white;
    text-align: center;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    display: flex;
  }
}

.navbar-brand {
  font-weight: bold;
  font-size: 30px;
  color: #1F57F8;
  font-family: 'Fonarto', sans-serif;

}

.navbar-text {
  font-size: 1rem;
}



.nav-link i {
  margin-right: 0.7rem;
}

.nav-link:hover {
  background-color: #e9ecef;
  color: #343a40;
  border-radius: 9px;
}

.nav-link.active {
  background-color: #e9ecef;
  color: #343a40;
  border-radius: 9px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}


button.btn.sidebar-toggle.navbar-toggler-icon {
  display: none !important;

  @media only screen and (max-width: 768px) {
    display: block !important;
  }
}

/* media queries */
@media only screen and (max-width: 768px) {


  .navbar {
    width: 100%;
    justify-content: left;
    position: absolute;
    margin-bottom: 1rem;
    margin-top: 3.5rem;
    z-index: 1050;
    margin-left: 0 !important;
    background: white;
    border-right: 0px solid lightgray;
  }

  .sidebar {
    margin-top: 7rem;
    left: -250px;
    height: 100%;
    border-right: 0px solid lightgray;
    width: 250px;
    background-color: white;
    z-index: 1020;
    overflow-y: scroll;
    padding: 20px;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;

    @media (max-width:576px) {

      height: 302vh;
    }
  }
}

@media (max-width:600) {
  .sidebar {
    width: 250px;
    z-index: 1020;
    background-color: white;
  }

  .navbar button.btn.sidebar-toggle {
    display: block;
    background: blue;
    width: 10rem;
    height: 10rem;
    border: none;
  }

}

@media (min-width:600) {

  .sidebar-toggle {
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    border: none;
    display: none !important;
  }
}