.selected_plan {
    padding: 5rem;

    @media screen and (max-width:576px) {
        padding: 0rem;

    }


    .staticCards {
        display: flex;
        margin-left: 5rem;

        @media screen and (max-width:576px) {
            margin-left: 1rem;
        }

        .card {
            width: 21%;

            @media screen and (max-width:576px) {
                width: 45%;
                margin-inline: 0.5rem;
                img{
                    width: 30%;
                }

            }

        }
    }



    .tasks {
        margin-left: 14rem;
        @media screen and (max-width:576px) {
            margin-left: 1rem;
        }
    }

    .mb-4.nav.nav-tabs {
        margin-left: 14rem;
        margin-top: 1rem;
        @media screen and (max-width:576px) {
            margin-left: 1rem;
        }

        a.nav-link {
            color: black;
            background: transparent;

        }

        a.nav-link.active {
            color: white;
            background: #1F57F8;
        }
    }

}


.blue-ribbon {
    width: 20rem;
    border-top: 60px solid #1F57F8;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    transform: rotate3d(1, 1, 1, 55deg);
    position: absolute;
    top: 0;
    height: 25rem;
    right: 0px;
}

.logoDisplay {
    font-weight: bold;
    font-size: 60px;
    color: #1F57F8;
    font-family: 'Fonarto', sans-serif;
}

.topHeader {
    background-color: #1F57F8 !important;
    color: white !important;
}

.totalButton {
    background: #1F57F8;
    color: white;
    padding: 7px 1px 1px 2px;
}

.card-background {
    position: absolute;
    top: -15px;
    left: -16px;
    width: 62vw;
    height: 66vh;
    object-fit: cover;
    background-image: url('../../assets/img/backg.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.4;

    @media screen and (max-width:576px) {
        width: 120vw;
        height: 51.5vh;
    }
}

.card-body-content {
    z-index: 200;
}

.flashing-button {
    animation: flash 1s infinite alternate;
}

@keyframes flash {
    0% {
        box-shadow: 0 0 10px 5px rgba(3, 132, 224, 0.6);
    }

    30% {
        box-shadow: 0 0 10px 5px rgba(3, 132, 224, 0.6);
    }

    100% {
        box-shadow: 0 0 10px 5px rgba(3, 132, 224, 0.3);
    }
}

.inactiveModal {
    .modal-backdrop.show {
        opacity: 0.7 !important;
        width: 100%;
        height: -webkit-fill-available;
    }
}