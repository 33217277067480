.IssueReport {
    padding: 4rem;
    text-align: left;
    .tasks{
        margin-top: 0rem;
        width: 100%;
        padding: 2rem;
        @media only screen and (max-width: 576px) {
            width: 119%;
            padding: 1rem;

        }
        h2 {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.5rem;
        }
        button.many{
            float: right !important;
            border: none;
            color: white;
            width: 8%;
            margin-bottom: 2rem;
        }
        .table{
            .table-body{
                button{
                    width: auto;
                }
            }
        }
        .pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            button {
                border: none;
                padding: 0.5rem;
                border-radius: 5px;
                background: #81c5d9;
                color: white;
                font-weight: bold;
            }
            span {
                font-size: large;
                font-style: italic;
            }
        }

    }
    .footer_rent {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 1rem;
        h2{
            text-align: center;
            color: var(--primary-color);
            text-transform: uppercase;
            width: 100vw;
        }
    }
}