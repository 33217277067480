.repairs {
    padding: 5rem;
    text-align: left;
    margin-left: 12rem;
    @media screen and (max-width: 576px) {
      margin-left: 0rem;
      padding: 2rem;


    }
    .topSelectedProperty {
        height: 40vh;
        margin-inline: 0.5rem;
        width: 102%;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 1rem;
        @media screen and (max-width: 768px) {
          margin-top: 9rem;
        }
      
        @media screen and (max-width: 576px) {
          margin-top: 2rem;
        }
      }
      .properties {
        color: white;
        padding: 3rem;
        font-size: 3rem;
        margin-left: 0 !important;
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 4px 6px 14px #000000;
  
       
      }
    .filterCalendar{
        margin-bottom: 0rem;
    }

    .tasks{
        margin-top:4rem;
    }
    .housesCards {
        flex-flow: wrap;
        border-radius: 1.5rem;
        width: 110%;
        margin-bottom: 1rem;
        margin-inline: 0rem;
        display: flex;
        justify-content: flex-start;
    }
}