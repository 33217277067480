.profileHeader{
    float:left;
    margin-inline: 1rem;
}
.profileBody{
position:relative;
margin-top:5rem;
}
.spanSpace{
    color:transparent;
}
.profileImage{
    border-radius: 50%;
    width: 7%;
}
.userProperty{
    background-color: #e4e4e4;
}
.userProperty h3 {
    
    padding: 0.5rem;
}
.houseDeatilsCard {
    padding-bottom: 2rem;
    width: 75%;
    padding-top: 3rem;
}
