.report_Apartment {
    padding: 5rem;
    text-align: left;
    margin-left: 12rem;
    @media screen and (max-width: 576px) {
        margin-left: 0rem;
        padding: 2rem;

    }
    .occupancyCard {

        // background-color: #3498db;
        // box-shadow: #3498db8f 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: rgb(70 187 200);
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #3498db;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #3498db;
        }
    }

    .incomeCard {

        // background-color: #8e44ad;
        // box-shadow: #8e44ad9e 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #bb60e1f0;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #8e44ad;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #8e44ad;
        }
    }

    .paymentCard {

        // background-color: #f1c40f;
        // box-shadow: #f1c40fb5 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #ffd800;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #f1c40f;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #f1c40f;
        }
    }

    .utilitiesCard {

        // background-color: #f39c12;
        // box-shadow: #f39c1299 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #ffbc52fc;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #f39c12;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #f39c12;
        }
    }

    .failedCard {

        // background-color: #1abc9c;
        // box-shadow: #1abc9c78 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #00ffcd;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #1abc9c;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #1abc9c;
        }
    }

    .overpaymentCard {

        // background-color: #2ecc71;
        // box-shadow: #2ecc7182 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #36ff8b;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #2ecc71;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #2ecc71;
        }

    }

    .systemCard {

        // background-color: #d35400;
        // box-shadow: #d3540096 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #ff6600;
            color: white;
            border: none;
        }

        .apexcharts-menu.apexcharts-menu-open {
            background: #ff6600;
            color: black !important;
        }

        h3 {
            font-size: 1.25;
            color: #d35400;
        }
    }

    .issuesCard {

        // background-color: #95a5a6;
        // box-shadow: #95a5a67d 8px 8px 15px 1px;
        // color: white;
        button {
            background-color: #b6c1c1;
            color: white;
            border: none;
        }

        h3 {
            font-size: 1.25;
            color: #95a5a6;
        }
    }

    .ReportCards {
        height: fit-content;
        box-shadow: #95a5a67d 8px 8px 15px 1px;
        border: none;
        margin-bottom: 1rem;

        .apexcharts-canvas {
            margin-left: 0rem;
            background-color: white;
        }

        p {
            font-size: 0.9rem;
        }


        .paymentDetailsReport {
            display: flex;
            justify-content: space-between;

            p {
                line-height: none;
                margin-bottom: 0.2em;
            }
        }

    }

    .rent {
        margin-top: 4rem;

        .tasks {
            margin-top: 0rem;
            width: 103%;
            padding: 2rem;

            h2 {
                color: var(--primary-color);
                text-transform: uppercase;
                font-size: 1.5rem;
            }

            button.many {
                float: right !important;
                border: none;
                color: white;
                width: 5%;
                margin-bottom: 2rem;
            }

            .table {
                .table-body {
                    button {
                        width: auto;
                    }
                }
            }

            .pagination {
                display: flex;
                justify-content: space-between;
                margin-top: 2rem;

                button {
                    border: none;
                    padding: 0.5rem;
                    border-radius: 5px;
                    background: #81c5d9;
                    color: white;
                    font-weight: bold;
                }

                span {
                    font-size: large;
                    font-style: italic;
                }
            }

        }
    }

    .cardProperty.card {
        margin-inline: 3rem;
        width: 90% !important;
        padding: 1rem;

        select.formProperty.form-select.form-select-sm {
            height: 40px;
            margin-top: 1rem;
            width: 100%;
            margin-right: 14rem;
        }

        select.formPropertySearch.form-select.form-select-sm {
            height: 40px;
            margin-top: 1rem;
            width: 100%;
            margin-right: 38rem;
            margin-left: -26rem;
        }
    }

    .image_Apartment_report {
        img {
            width: 100%;
            height: 50vh;
            object-fit: cover;
        }

        p {
            margin-top: -15rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 3rem;
            color: white;
            font-weight: bold;
            text-shadow: 4px 6px 14px #000000;
            @media screen and (max-width: 576px) {
                margin-top: -20rem;

        
            }
        }

        span {

            display: flex;
            justify-content: center;
            text-transform: uppercase;
            font-size: 1rem;
            color: white;
            font-weight: bold;
            text-shadow: 4px 6px 14px #000000;
        }
    }

    .staticCards {
        justify-content: space-between;
        margin-top: 4rem;

        .rent {
            background-color: var(--primary-color);
        }

        .utilities {
            background-color: var(--secondary-color);
            color: white;
        }

        .occupancy {
            background-color: #8e44ad;
            color: white;
        }

        .income_report {
            background-color: #f39c12;
            color: white;
        }

        .owners {
            background-color: var(--EmeraldGreen-color);
            color: white;
        }

        .card {
            margin-inline: 0rem;
            font-size: 20px;
            font-weight: bold;
            height: 25vh;

            img {
                margin-inline: 5rem;
            }

            .card-body {
                display: block;
            }
        }
    }

    .progressCard {
        margin-top: 4rem;
        float: left;

        .rentexpenses {
            position: relative;
            margin-top: 0rem;
            margin-inline: 1rem;
        }
    }

}
.filterCardSectionReports {
    padding-top: 9rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: -7rem;
    select.formCal.form-select.form-select-sm {
        width: 25%;
    }
    p{
        font-size: 1.3rem;
        text-transform: uppercase;
    }
}