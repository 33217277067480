.wallet_dashboard {
    padding: 5rem;
    @media screen and (max-width: 576px) {
        padding: 2rem;

    }
    .topSelectedProperty {
        height: 40vh;
        border-radius: 0.5em;
        margin-inline: 12.5rem;
        width: 90%;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: 768px) {
            margin-top: 9rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: 1rem;
            width: 100%;

            margin-inline: -0.5rem;
            img{
                width: 115vw;
            }

        }

        .properties {
            color: white;
            padding: 3rem;
            font-size: 3rem;
            margin-left: 0 !important;
            font-weight: 900;
            text-transform: uppercase;
            text-shadow: 4px 6px 14px #000000;

            @media screen and (max-width: 576px) {
                padding: 2rem;

            }
        }
    }

    .tasks {
        margin-top: 0rem;
        width: 90%;
        padding: 2rem;
        margin-left: 12rem;
        @media screen and (max-width: 576px) {
            margin-left: 1rem;

        }

        h2 {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.5rem;
            @media screen and (max-width: 576px) {
                font-size: 0.6rem;
    
            }
        }

        button.many {
            float: right !important;
            border: none;
            color: white;
            width: 8%;
            margin-bottom: 2rem;
        }

        .table {
            .table-body {
                button {
                    width: auto;
                }
            }

            p {
                color: var(--primary-color);
                text-transform: uppercase;

            }
        }


    }

    .staticCards {
        margin-inline: 0rem;
        width: 110%;
        margin-top: -4rem;

        @media screen and (max-width: 576px) {
            margin-top: -1rem;
        }

        .card {
            width: 19%;
            @media screen and (max-width: 576px) {
                width: 65%;
                margin-left: 5rem;
            }
            .card-body {
                display: block;

                p.cost {
                    font-size: 21px !important;
                    font-weight: bold;
                }

                p {
                    font-size: 15px;
                    font-weight: normal;
                }

                span {

                    font-size: 18px;

                }

                .notificationSpan {
                    // background: #0d6efd;
                    background: red;

                    border-radius: 50%;
                    padding: 0.7rem;
                    width: 1.5vw;
                    display: flex;
                    height: 2vh;
                    position: absolute;
                    top: 40%;
                    font-size: 0.8rem;
                    color: white;
                    right: 20%;
                    place-items: center;
                    justify-content: center;
                }

            }

        }

        .card {
            .card-body {
                display: flex;
                text-align: justify;
            }



        }
    }

    .account_details {
        margin-top: 2rem;
        margin-left: 13rem;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 576px) {
            margin-left: 1rem;

        }

        .card {
            background-color: white;
            border: none;
            width: 35vw;
            box-shadow: 2px 2px 2px 3px lightgray;
            @media screen and (max-width: 576px) {
                width: 50vw;
    
            }
            input.input-field {
                font-size: 2rem;
                color: gray;
            }

            .icon {
                margin-top: 0.75rem;
            }


        }

        span {
            font-weight: bold;
            font-size: 1.5rem;
            margin-top: 0.2rem;
            margin-left: 0.5rem;
        }

        p.Balance {
            color: #2ecc71 !important;
        }
    }

}

.withLabel {
    width: 10vw;
    border: 0;
}

.inputWithdraw {
    border: 0;
    background: #dedeff;
    padding: 10px;
    margin-bottom: 10px;
    width: 65%;
}

.calculatorInput {

    border: 0;
    background: #dedede;
    width: 100%;
    padding: 10px;

}