.HistoryReport {
    text-align: left;
    margin-top: 2rem;
  
    
    // width: 70vw;
    .tasks{
        margin-top: 0rem;
        width: 90%;
        padding: 2rem;
        margin-left: 3rem;
        @media screen and (max-width: 576px) {
            margin-left: 1rem;
            width:95%;
        }
        h2 {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.5rem;
        }
        button.many{
            float: right !important;
            border: none;
            color: white;
            width: 8%;
            margin-bottom: 2rem;
        }
        .table{
            .table-body{
                button{
                    width: auto;
                }
            }
            p{
                color: var(--primary-color);
                text-transform: uppercase;
               
            }
        }
       

    }

}
.Download{
    border-radius: 50%;
    font-weight: bold;
}
.bottomDataHouseReport {
    display: flex;
    justify-content: space-between;
    width: 300%;
}
.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    button {
        border: none;
        padding: 0.5rem;
        border-radius: 5px;
        background: #81c5d9;
        color: white;
        font-weight: bold;
    }
    span {
        font-size: large;
        font-style: italic;
    }
}