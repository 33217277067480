.profile_dashboard {
    padding: 5rem;

    @media screen and (max-width: 576px) {
        padding: 2rem;
    }

    h2 {
        text-align: left;
        margin-left: 12rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 576px) {
            margin-left: 1rem;
            margin-top: 2rem;
        }
    
    }

    .landlord_profile.card {
        margin-left: 12rem;
        box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
        border: none;
        border-radius: 20px;
        @media screen and (max-width: 576px) {
            margin-left: 1rem;
        }

        .card-body {
            img {
                width: 6rem;
                height: 6rem;
                border-radius: 50%;
                margin-left: 3rem;
            }

            .editImage {
                position: absolute;
                margin-top: 0rem;
                margin-left: -0.2rem;
            }

            .landlord_type {
                .landlord {
                    background-color: #2ecc71;
                    text-transform: capitalize;
                }

                .caretaker {
                    background-color: #f39c12;
                    text-transform: capitalize;
                }

                .agent {
                    background-color: #8e44ad;
                    text-transform: capitalize;
                }


            }
        }
    }
}

td.landlord {
    color: var(--EmeraldGreen-color);
    text-transform: capitalize;
}

td.agent {
    color: #8e44ad;
    text-transform: capitalize;
}

td.caretaker {
    color: #f39c12;
    text-transform: capitalize;
}

.imageTableProfile {
    border-radius: 0.5em;
}

.display-none {
    display: none;
}

.container-crop {
    position: absolute;
    top: 14%;
    left: 32%;
}

.cropper {
    position: relative;
    // margin-top: 7rem;
}

.reactEasyCrop_Container {
    top: 100% !important;
    margin-left: 12rem;
}

img.cropped-img {
    width: 20rem;
    margin-bottom: 2rem;
}