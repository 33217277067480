.loginForm {
  box-shadow: rgba(17, 12, 46, 0.15) 10px 48px 100px 15px;
  width: 31%;
  margin-inline: auto;
  padding: 1rem;
  margin-top: 9%;
  height: fit-content;
  border-radius: 10px;

}
@media (max-width: 800px) and (min-width: 100px) {
  .loginForm {
    width: 80%;
    margin-top: 4rem;
  }
}

.loginForm h2 {
font-size: 22px;
}

.loginForm p {
  font-size: 13px;
  }

.custom-back-button::before {
  content: "<";
  font-size: 25px;
  font-weight: bold;
  color: black;
}


.custom-back-button {
  display: inline-block;
  padding: 2px;
  float:left;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 18px;
}

.icon {
  padding: 15px;
  color: grey;
  min-width: 50px;
  text-align: center;
}

.input-field,
.input-field:focus {
  width: 100%;
  padding: 10px;
  outline-color: var(--outline-color);
  text-align: start;
  padding-left: 50px;
  border-radius: 17px;
  font-size: 15px;
  border: 1px solid grey;
}

.privatePolicy,
.privatePolicy:hover {
  color: orange;
}

.loginBtn {
  width: 75%;
  margin-inline: 10%;
  height: 6vh;
  border-radius: 30px;
  background-color: var(--primary-color);
  margin-bottom: 2rem;
  border: none;
}
.toSignUp,
.toSignUp:hover {
  color: rgb(5, 237, 44);
}
.noAccount {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 2;
}
.forgotPassword {
  display: flex;
  align-items: flex-start;
  padding: 0px 0px 25px 20px; /*top right bottom left*/
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
}
.toSignUp {
  text-decoration: none;
}
.termsAndConditions{
  padding-bottom: 1rem;
}
.termsAndConditions h6 {
  font-size: 15px;
  line-height: 2;
}