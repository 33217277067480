.homeCard {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    width: 33%;
    margin-inline: auto;
    padding: 1rem;
    margin-top: 0.5rem;
    border-radius: 35px;
    height: 100%;
}

@media (max-width: 800px) and (min-width: 100px) {
    .homeCard {
        width: 80%;
        margin-top: 4rem;
    }
}

.image-container {
    position: relative;

}

.imageHome {
    width: 100% !important;
    height: 50vh;
    overflow: hidden;
    border-radius: 1rem;
}

.image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 55px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.wave {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: #ffffff;
    border-radius: 8% / 22px;
    transform: rotate(-2deg);
}

.main-content h2 {
    letter-spacing: 0.2px;
    font-size: 22px;
    color: var(--secondary-color);
}

.main-content p {
    letter-spacing: 0.2px;
    font-size: 15px;
    color: var(--secondary-color);
}

.kodihome {
    font-weight: bold;
    font-size: 30px;
    color: #1F57F8;
    margin-inline: 15rem;
    position: absolute;
    font-family: 'Fonarto', sans-serif;
}

.propertiesViewImage {
    width: 27vw;
    height: 50vh;
    object-fit: cover;
    margin-top: -2.3rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 576px) {
        width: 25vw;
        height: 20vh;

    }
}

.kodihome-landing {
    font-weight: bold;
    font-size: 30px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 6%;
    transform: translate(-50%, -50%);
    color: #1F57F8;
    display: flex;
    position: absolute;
    font-family: 'Fonarto', sans-serif;
}

.SelectRoomImage {
    position: relative;
    padding: 0.8rem;
    background-color: red;
    border-radius: 50%;
    margin-left: -13rem;
    
    @media only screen and (max-width: 576px) {
        margin-left: -19rem;
    }
}