/* the top of the page */
.headerMain {
    width: 97%;
    display: flex;
    justify-content: space-between;
    margin-inline: 1rem;
    margin-top: 0.5rem;
}

/* search bar of the page */
.searchBar{
    width:25%
}
.searchBar input {
    border: 1px solid lightgray;
    background-color: white;
}

/* items searched for */
.itemSearch {
    border: none;
    display: flex;
    align-items: self-end;
    text-align: end;
    width: 97%;
    margin-inline-end: 1rem;

}
.items{
    width: 24%;
    text-align: justify;
    border-radius: 10px;
    margin-bottom:0.5rem;
}
.houseButtons {
    display: flex;
    justify-content: space-between;
}
