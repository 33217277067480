/* the top div with the same color as the navbar */
.headerHome{
    position: fixed;
    top:0;
    width: 100%;
    height: 36%;
    padding:0rem;
    background-color: var(--secondary-color);

}
/* the houses in carousel */
.ApartmentCardUtilities {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: black;
    border-radius: 10px;
   
}
.ApartmentCardUtilities .card{
 
}