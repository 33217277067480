// Define custom styles for the modal

.custom-modal {
    // Customize modal header
    .modal-header {
      background-color: #f8f9fa; // Light background color
      border-bottom: none; // Remove default border
      .modal-title {
        font-size: 1.5rem; // Larger title font size
        color: #007bff; // Blue color for title
        text-transform: uppercase; // Uppercase text
      }
    }
  
    // Customize modal body
    .modal-body {
      background-color: #fff; // White background color
      padding: 20px; // Add padding
      .subscription-balance {
        font-size: 1.1rem; // Increase font size
        margin-bottom: 20px; // Add margin
        b {
          color: #6c757d; // Dark gray color for balance value
        }
      }
      .payment-method-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .btn {
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          &.wallet {
            background-color: #ffc107; // Yellow color for wallet button
            color: #fff; // White text color
          }
          &.mpesa {
            background-color: #007bff; // Blue color for Mpesa button
            color: #fff; // White text color
          }
        }
      }
      .wallet-select {
        margin-top: 20px;
        .form-select {
          border-radius: 5px;
          padding: 10px;
          font-size: 1rem;
          &:focus {
            border-color: #007bff; // Blue border color on focus
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); // Blue shadow on focus
          }
        }
      }
      .amount-input {
        border-radius: 5px;
        padding: 10px;
        font-size: 1rem;
        margin-top: 20px;
        &.invalid-amount {
          border-color: #dc3545; // Red border color for invalid amount
        }
      }
      .balance-message {
        font-size: 0.9rem;
        color: purple; // Purple color for balance message
      }
      .insufficient-funds-message {
        color: #dc3545; // Red color for insufficient funds message
      }
      .balance-after-transaction {
        font-size: 0.9rem;
        color: goldenrod; // Goldenrod color for balance after transaction
      }
    }
  
    // Customize modal footer
    .modal-footer {
      background-color: #f8f9fa; // Light background color
      border-top: none; // Remove default border
      .btn-pay {
        background-color: #28a745; // Green color for pay button
        color: #fff; // White text color
        font-weight: bold;
        opacity: 0.75;
        &:hover {
          opacity: 1; // Full opacity on hover
        }
      }
    }
  }
  