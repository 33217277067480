.App {
  text-align: center;
}

@media (max-width: 1300px) and (min-width: 1201px) {
  .App {
    zoom: 0.9;
  }

}

@media (max-width: 1200px) and (min-width: 1100px) {
  .App {
    zoom: 0.8;
  }
}

@media (max-width: 1099px) and (min-width: 996px) {
  .App {
    zoom: 0.7;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App.app_dark {
  background-color: #2d3436;
  color: white;

  .progressCard .card {
    float: right;
    display: flex;
    margin-top: 1rem;
    background-color: #2d3436;
  }

  .progressBar .card-header {
    border-radius: 20px;
    background-color: #2d3436;
    color: white;
  }

  svg {
    background-color: #2d3436 !important;


  }

  span.apexcharts-legend-text {
    color: white !important;

  }

  .rentDue {
    background-color: rgb(192, 222, 220);
    box-shadow: none;
  }

  .defaulters {
    background-color: rgb(230, 222, 241);
    box-shadow: none;
  }

  .income {
    background-color: rgb(241, 238, 232);
    box-shadow: none;
  }

  .occupacy {
    background-color: rgb(240, 223, 223);
    box-shadow: none;
  }

  text {
    color: white !important;

  }

  .tasks {
    background-color: #2d3436;
    color: white;

    .table {
      --bs-table-bg: #2d3436 !important;
      color: white;

      td {
        color: white;
      }
    }
  }
}