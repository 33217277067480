.subscription_properties {
    padding: 5rem;

    @media screen and (max-width: 576px) {
        padding: 1rem;
    }

    .topSelectedProperty {
        height: 35vh;
        margin-inline: 12.5rem;
        width: 90%;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url('../../assets/img/subscription_1.png');

        @media screen and (max-width: 768px) {
            margin-top: 9rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: 2rem;
            margin-inline: 1.5rem;

        }
    }

    .properties {
        color: white;
        padding: 3rem;
        font-size: 3rem;
        margin-left: 0 !important;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 4px 6px 14px #000000;

        @media screen and (max-width: 768px) {
            font-size: 2rem;

        }
    }

    h4 {
        float: left;
        margin-left: 6rem;
        background-color: white;
        box-shadow: rgba(12, 12, 46, 0.15) 9px 2px 24px 1px;
        padding: 1rem;
        width: 100%;
        margin-top: -1rem;
        margin-bottom: 3rem;
    }

    .staticCards {
        margin-inline: 1.2rem;
        width: 110%;
        margin-top: -3.5rem;

        @media screen and (max-width: 768px) {
            margin-inline: 3.2rem;

        }

        .card {
            width: 20%;

            @media screen and (max-width: 768px) {
                width: 80%;

            }

            .card-body {
                display: block;

                p.cost {
                    font-size: 17px !important;
                    font-weight: bold;
                }

                p {
                    font-size: 15px;
                    font-weight: normal;
                }

                span {

                    font-size: 18px;

                }
            }

        }

        .card {
            .card-body {
                display: flex;
                text-align: justify;
            }



        }
    }

    .tasks {
        margin-top: 3rem;
        margin-left: 12rem;

        .imageTableDashboard {
            width: 70px;
            height: 47px;
            @media screen and (max-width: 768px) {
                width: 40px;
                height: 30px;    
            }
        }

        @media screen and (max-width: 768px) {
            margin-left: 2rem;
            font-size: 0.5rem;
            button{
                zoom: 0.3;
            }

        }
    }
}