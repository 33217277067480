.incomeReport {
    padding: 5rem;
    text-align: left;
    margin-left: 12rem;
   
    .tasks{
        margin-top: 0rem;
        width: 103%;
        padding: 2rem;
        h2 {
            color: var( --EmeraldGreen-color);
            text-transform: uppercase;
            font-size: 1.5rem;
        }
        .d-flex.justify-content-between {
            width: 98vw;
            margin-bottom: 2rem;
            .searchBar{
                width: auto;
               input {
                    border: 1px solid lightgray;
                    background: white !important;
           
                }
            }
        }
        .table{
            .table-body{
                button{
                    width: auto;
                }
            }
        }
        .pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            button {
                border: none;
                padding: 0.5rem;
                border-radius: 5px;
                background: #81c5d9;
                color: white;
                font-weight: bold;
            }
            span {
                font-size: large;
                font-style: italic;
            }
        }

    }
    .footer_rent {
        position: fixed;
        bottom: 0;
        padding: 1rem;
        i{
            border-radius: 50%;
            right: 0;
            position: fixed;
        }
        h2{
            color: var(--primary-color);
            text-transform: uppercase;
            text-align: center;
            font-size: 1rem;
        }
        p{
            background-color: var(--materialorange-color);
            text-align-last: center;
            width:100vw;
        }
    }
}