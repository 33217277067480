/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  } */
.property {
    padding: 4rem;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        padding: 0;
        overflow-x: initial;

    }

    .topSelectedProperty {
        height: 40vh;
        border-radius: 0.5em;
        margin-inline: 12.5rem;
        width: 90%;
        padding: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: 768px) {
            margin-top: 9rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: 5rem;
            margin-inline: 1.5rem;
            position: inherit;
            display: flex;

        }
    }

    .properties {
        color: white;
        padding: 3rem;
        font-size: 3rem;
        margin-left: 0 !important;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 4px 6px 14px #000000;

        @media screen and (max-width: 768px) {
            font-size: 3.5rem;
            padding: 4rem;


        }
    }
}

.cardProperty {
    box-shadow: rgba(17, 12, 46, 0.15) 2px 1px 0px 1px;
    padding: 0.5rem;
    // margin-inline: 13.5rem;
    margin-inline: 12.5%;
    margin-top: -3rem;
    height: 13vh;
    border-radius: 0;
    width: 88% !important;
    display: grid;
    grid-auto-flow: column;
    // justify-content: space-between;

    @media screen and (max-width: 992px) {
        // margin-inline: 11rem;
        margin-inline: 20.5%;
        width: 83% !important;

    }

    @media screen and (max-width: 576px) {
        margin-top: -3rem !important; 
        margin-inline: 2rem;
        width: 85% !important;

    }


    // @media screen and (max-width: 768px) {
    //     margin-inline: 0.5rem;
    //     margin-top: 8rem;
    //     width: 97% !important;
    //     box-shadow: rgba(17, 12, 46, 0.15) 4px 1px 0px 5px;
    // }

}

.searchProperty {
    padding: 1rem;
    width: 35rem;

    @media screen and (max-width: 992px) {
        width: 20rem;
    }

    @media screen and (max-width: 892px) {
        width: 15rem;
    }

    @media screen and (max-width: 768px) {
        width: 10rem;
    }
    @media screen and (max-width: 576px) {
        width: 13rem;
    }
}

select.formProperty.form-select.form-select-sm {

    height: 40px;
    margin-top: 1rem;
    width: 100%;
    margin-right: 8rem;
}

.findNow {
    background: red;
    border: none;
    height: 3rem;
    margin-top: 1rem;

    :hover,
    .active,
    focus {
        background: red;
    }
}


.propertyButton {
    margin-bottom: 1rem;
    margin-top: 1rem;
    float: right;
}

.card-body .image-container {
    position: relative;
    // display: inline-block;
}

.image-container .badge {
    position: absolute;
    top: 10px;
    left: 10px;
    font-family: var(--font-family);
    background-color: white;
    color: black;
    padding: 6px;
    z-index: 1;
}





.card-body .image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.housesCards {
    flex-flow: wrap;
    border-radius: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-inline: 12.5rem;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 992px) {
        margin-inline: 11rem;
    }

    @media screen and (max-width: 768px) {
        margin-inline: 0;
    }

    .house {
        width: 20%;
        margin-inline: 1.5rem;
        margin-bottom: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        @media screen and (min-width: 1800px) {
            width: 17%;
        }

        @media screen and (min-width: 2000px) {
            width: 14%;
        }

        @media screen and (min-width: 2400px) {
            width: 12%;
        }

        @media screen and (min-width: 2700px) {
            width: 10%;
        }

        @media screen and (min-width: 3300px) {
            width: 8%;
        }

        @media screen and (min-width: 3300px) {
            width: 6%;
        }

        @media screen and (max-width: 992px) {
            width: 25%;
        } 
          @media screen and (max-width: 576px) {
            width: 40%;
        }

        .image-container p.text-muted.float-left {
            float: left;
            font-size: 11px;
            margin-top: 0.5rem;
            margin-bottom: 0rem;

            @media screen and (max-width: 768px) {
                font-size: 9px;
            }
        }

        .houseName {
            text-align: left;

            @media screen and (max-width: 768px) {
                font-size: 13px;
            }

        }

        img {
            display: block;
            width: 100%;
            height: 11rem;
        }

    }
}



.buttonsProperties {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 75%;
        place-items: center;
    }

    a {
        @media screen and (max-width: 768px) {
            font-size: 11px;
        }
    }

    .btn {
        @media screen and (max-width: 768px) {
            font-size: 11px;
            margin: 0;
            padding: 2px;
        }
    }
}





.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-backdrop.show {
    opacity: 0.2;
    width: 100%;
    height: -webkit-fill-available;
}

.property-grid {
    border-radius: 0.5em;
}