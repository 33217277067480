.report_Tenant{
    padding: 5rem;
    margin-left: 12rem;
    .staticCards{
        justify-content: flex-start;
        margin-inline: 1rem;
        .utilities{
            background-color: var(--secondary-color);
            color: white;
        }
        .issue{
            background-color: #f39c12;
            color: white;
        }

        .card{
            margin-inline: 1rem;
            font-size: 20px;
            font-weight: bold;
            height: fit-content;
            img{
                margin-inline: 5rem;
            }
            .card-body{
                display: block;
            }
        }
    }
}