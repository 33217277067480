.messagesPage {
    float: right;
    margin-top: 5rem;
    width: calc(100% - 270px);

    @media screen and (max-width: 576px) {
        width: 100%;
        margin-top: 0rem;
        padding: 0.5rem;
    }



    .messageTopImage {
        height: 50vh;
        color: white;
        font-size: 4rem;
        text-shadow: 4px 6px 14px #000000;
        font-weight: 900;
        width: 99%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .tasks{
        @media screen and (max-width: 576px) {
            margin-left: 0rem ;
        }

    }

    .metrics {
        display: none;
        justify-content: space-around;

        .card {
            border: 0;
            width: 25%;
            height: 20vh;
            border-radius: 20px;

            .card-body {
                display: flex;

                img {
                    margin-top: 1rem;
                    height: 10vh;
                }
            }
        }
    }

    .staticCards {
        @media screen and (max-width: 576px) {
            margin-left: 0rem ;
        }
        .card {
            border-radius: 30px;
            border: none;
            width: 23%;
            margin-inline: 1rem;
            @media screen and (max-width: 576px) {
               width: 65%;
               margin-top: 2rem !important;
               margin-inline: 5rem;
            }
           
        }
    }


}